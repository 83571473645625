import { useLocale, useResourceShow, useResourceUpdate, useUserShow } from '@koopajs/react';
import { IResolution } from 'types';
import { Button, Box, Stack, Typography, Paper, Divider, Link, Breadcrumbs } from '@mui/material';
import { ResolutionOutcomeText } from 'components/ResolutionOutcome/ResolutionOutcomeText';
import { DocumentList } from 'components/DocumentList';
import { Link as RouterLink } from 'react-router-dom';
import { SignatureList } from 'components/Signature/SignatureList';
import { ErrorMessage } from '@koopajs/mui';
import { useCallback, useRef } from 'react';
import { ResolutionOutcomeIcon } from 'components/ResolutionOutcome/ResolutionOutcomeIcon';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { WarningAmber as WarningAmberIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { MissingSigneesList } from 'components/Dashboard/ToSign/MissingSigneesList';
import { calculateMissingSignatureCount } from 'utils/calculateMissingSignatureCount';
import { isEmpty } from 'lodash';
import { TopicMinutesDetailsCard } from 'components/TopicMinutesDetailsCard';
import { Helmet } from 'react-helmet';
import { getSignaturesWithTitles } from 'utils/getSignaturesWithTitles';
import { ViewResolutionPrintVersion } from 'components/Dialogs/ViewResolutionPrintVersion';
import { RenderPrintButton } from 'components/RenderPrintButton';
import { PageContainer } from 'components/temp/PageContainer';
interface IToSignResolutionProps {
  resolution: IResolution;
}

export const ToSignPageResolution: React.FC<IToSignResolutionProps> = (props) => {
  const { resolution } = props;
  const componentRef = useRef(null);

  const { t } = useLocale();
  const keyPrefix = 'ToSign';

  const { user } = useUserShow();

  const { updateResource: updateTopic } = useResourceUpdate({
    path: '/items-to-sign/resolution',
    id: resolution.id,
    customReducer: {
      path: {
        resourceType: `/resolutions`,
        resourceId: resolution.id
      },
      mapping: (prevObj: unknown, newObj: unknown) => {
        const updatedResolution = newObj as IResolution;

        return updatedResolution;
      }
    }
  });

  const handleSignResolution = useCallback(async (): Promise<boolean> => {
    return await updateTopic({});
  }, [updateTopic]);

  const { errorMessage } = useResourceShow<IResolution>({
    path: '/resolutions',
    id: resolution.id
  });

  const canUserSign = Boolean(resolution?.membersLeftToSign?.some((m) => m.userId === user?.id));
  const membersLeftToSignIds = resolution?.membersLeftToSign?.map((m) => m.userId);

  const signaturesWithTitles = getSignaturesWithTitles(resolution);

  if (!resolution) return null;

  return (
    <>
      <PageContainer sxChildren={{ padding: '24px' }} className="rr-block">
        <Helmet>
          <title>{`${t('common:navigation.toSignResolutions')} - Panorama`}</title>
        </Helmet>
        <Box sx={{ display: 'none' }}>
          <ViewResolutionPrintVersion resolution={resolution} ref={componentRef} />
        </Box>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="inherit"
            to="/to-sign"
            sx={{ cursor: 'pointer' }}
            component={RouterLink}
          >
            {t('common:navigation.dashboard')}
          </Link>
          <Typography color="text.primary">
            {canUserSign ? t(keyPrefix + '.titleSignResolution') : t(keyPrefix + '.titleViewResolution')}
          </Typography>
        </Breadcrumbs>

        <Box sx={{ mx: { lg: 9 } }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 2 }}>
            <Box>
              <Typography
                variant="h1"
                sx={{ display: 'inline-block', fontWeight: 500, fontSize: '20px', minWidth: '400px' }}
              >
                {canUserSign ? t(keyPrefix + '.titleSignResolution') : t(keyPrefix + '.titleViewResolution')}
              </Typography>
            </Box>
            {canUserSign && (
              <Button
                variant="contained"
                onClick={handleSignResolution}
                data-cy="to-sign-page-resolution_sign-button"
              >
                {t('common:sign')}
              </Button>
            )}
          </Stack>

          <>
            <ErrorMessage error={errorMessage} />

            {resolution.attachedToMeetingTopic?.minuteId && (
              <TopicMinutesDetailsCard
                minuteId={resolution.attachedToMeetingTopic.minuteId}
                title={t('Resolutions.viewResolutionDialogTitle')}
              />
            )}

            <Paper variant="outlined" sx={{ p: 3, my: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'baseline', my: 2 }}>
                {resolution?.resolutionNumber && (
                  <>
                    <Typography variant="h6" sx={{ display: 'inline-block', fontWeight: 500 }}>
                      #{resolution.resolutionNumber}
                    </Typography>
                    <Typography sx={{ mx: 3 }}>&#183;</Typography>
                  </>
                )}
                <Typography variant="body1">
                  {resolution.title
                    ? resolution.title
                    : resolution.attachedToMeetingTopic?.type
                    ? t('common:topicTypes.' + resolution.attachedToMeetingTopic.type)
                    : null}
                </Typography>
              </Box>

              {resolution?.notes && (
                <>
                  <Divider />
                  <Typography variant="body2" component="div" sx={{ my: 2 }}>
                    <RichTextReadOnly value={resolution.notes} />
                  </Typography>
                </>
              )}

              {resolution?.resolutionOutcome && !isEmpty(resolution.resolutionOutcome) && (
                <Typography variant="body1" sx={{ my: 2, display: 'flex', alignItems: 'flex-start' }}>
                  <ResolutionOutcomeIcon voteOutcome={resolution.resolutionOutcome?.outcome} />
                  <ResolutionOutcomeText topic={resolution} />
                </Typography>
              )}

              {resolution?.documentsIds && resolution?.documentsIds.length > 0 && (
                <Box sx={{ mb: 2 }}>
                  <DocumentList
                    path={`/resolutions/${resolution.id}/documents`}
                    isAccordion={true}
                    accordionOptions={{
                      defaultExpanded: true,
                      documentsVisible: 0
                    }}
                  />
                  <Divider sx={{ mt: 2 }} />
                </Box>
              )}
              {/* Signatures */}
              {signaturesWithTitles && (
                <Box sx={{ my: 4 }} data-cy="to-sign-page-resolution_signatures">
                  <SignatureList signatures={signaturesWithTitles} />
                </Box>
              )}
              {membersLeftToSignIds && membersLeftToSignIds.length > 0 && resolution && (
                <Box sx={{ mb: 2 }} data-cy="to-sign-page-resolution_missing-signatures">
                  <Stack direction="row" sx={{ mt: 3, mb: 2, alignItems: 'center' }}>
                    <WarningAmberIcon fontSize="small" sx={{ mr: 1 }} />
                    <Typography>
                      {t(`ToSign.ToSignItem.signaturesMissing`, {
                        count: calculateMissingSignatureCount(resolution)
                      })}
                    </Typography>
                  </Stack>
                  <MissingSigneesList signees={membersLeftToSignIds} />
                </Box>
              )}
            </Paper>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 4, alignItems: 'flex-start' }}>
              <>
                {/* eslint-disable-next-line react/jsx-no-bind */}
                <Button component={RouterLink} to="/to-sign" sx={{ display: { xs: 'none', sm: 'block' } }}>
                  {t('common:labelBack')}
                </Button>
                <Button
                  component={RouterLink}
                  to="/to-sign"
                  variant="outlined"
                  sx={{ minWidth: 0, px: '5px', display: { sm: 'none' } }}
                  aria-label={t('common:labelBack')}
                >
                  <ArrowBackIcon sx={{ color: 'primary.main' }} />
                </Button>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <RenderPrintButton
                    keyPrefix={keyPrefix + '.labelPrintResolution'}
                    componentRef={componentRef}
                    documentTitle={resolution?.title}
                    variant="outlined"
                  />
                  {canUserSign && (
                    <Button
                      variant="contained"
                      onClick={handleSignResolution}
                      data-cy="to-sign-page-resolution_sign-button"
                    >
                      {t('common:sign')}
                    </Button>
                  )}
                </Box>
              </>
            </Box>
          </>
        </Box>
      </PageContainer>
    </>
  );
};
