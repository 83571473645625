import { Box, Typography, Paper, Stack } from '@mui/material';
import { formatDate } from 'utils/DateTime/formatDate';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { IResolution } from 'types';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { useLocale, useWorkspaceShow } from '@koopajs/react';

interface IWrittenResolutionDialogDetailsCardProps {
  isPrintVersion?: boolean;
  resolution: IResolution;
}

export const WrittenResolutionDialogDetailsCard: React.FC<IWrittenResolutionDialogDetailsCardProps> = (
  props
) => {
  const { isPrintVersion, resolution } = props;

  const { locale, t } = useLocale();

  const title = resolution?.attachedToEmail ? t('common:writtenResolution') : resolution.title;

  const { workspace } = useWorkspaceShow();

  const committeeName = useCommitteeName(resolution.committeeId);

  return (
    <Paper
      sx={{ p: isPrintVersion ? 0 : 3, my: 2, border: isPrintVersion ? 0 : undefined }}
      variant="outlined"
    >
      <Stack sx={{ flexGrow: 1 }}>
        {/* desktop - title */}
        <Typography sx={{ display: { xs: 'none', md: 'flex' }, marginBottom: '14px' }} variant="h6">
          <Box component="span" sx={{ display: { xs: 'block', md: 'inline' } }}>
            {title}
          </Box>{' '}
          <Box component="span" sx={{ display: { xs: 'block', md: 'inline' } }}>
            &nbsp; | &nbsp;
          </Box>
          <Box component="span" sx={{ display: { xs: 'block', md: 'inline' } }}>
            {committeeName}
          </Box>
        </Typography>

        {/* mobile - title: 2 lines */}
        <Box
          sx={{
            display: { xs: 'flex', md: 'none' },
            marginBottom: '14px',
            flexDirection: 'column'
          }}
        >
          <Typography variant="h6">{title}</Typography>
          <Typography variant="h6">{committeeName}</Typography>
        </Box>

        <Typography sx={{ marginBottom: '14px', fontSize: '18px' }} variant="body1">
          {workspace?.name}
        </Typography>

        {/* DATE */}
        {resolution.sealedAt && (
          <Stack key="date" sx={{ flexDirection: 'row', alignItems: 'center' }}>
            {!isPrintVersion && (
              <CalendarTodayIcon color="action" sx={{ mr: { xs: 0.5, md: 2 }, opacity: 1 }} />
            )}
            <Typography variant="body2" sx={{ display: 'inline' }}>
              {formatDate({ isoString: resolution.sealedAt, locale })}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};
