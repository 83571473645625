import { IMinuteRef } from 'types';
import { Stack, Typography, Paper, Box } from '@mui/material';
import { useLocale, useResourceReference } from '@koopajs/react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { DateTimeStartEnd } from './DateTimeStartEnd';
import LocationOnIcon from '@mui/icons-material/LocationOn';

interface ITopicMinutesDetailsCard {
  minuteId: string;
  isPrintVersion?: boolean;
  title: string;
}

export const TopicMinutesDetailsCard: React.FC<ITopicMinutesDetailsCard> = (props) => {
  const { minuteId, isPrintVersion, title } = props;

  const minute = useResourceReference<IMinuteRef>(`ref:minute:${minuteId}`);

  const minutesType = useCommitteeName(minute?.committeeId || '');

  const { t } = useLocale();

  if (!minute) return null;

  return (
    <>
      <Paper
        sx={{ p: isPrintVersion ? 0 : 3, my: 2, border: isPrintVersion ? 0 : undefined }}
        variant="outlined"
        data-cy="topic-minutes-detail-card"
      >
        <Stack sx={{ flexGrow: 1 }}>
          {/* desktop - title */}
          <Typography sx={{ display: { xs: 'none', md: 'flex' }, marginBottom: '14px' }} variant="h6">
            <Box component="span" sx={{ display: { xs: 'block', md: 'inline' } }}>
              {title}
            </Box>{' '}
            {minutesType && (
              <>
                <Box component="span" sx={{ display: { xs: 'block', md: 'inline' } }}>
                  &nbsp; | &nbsp;
                </Box>
                <Box component="span" sx={{ display: { xs: 'block', md: 'inline' } }}>
                  {minutesType}
                </Box>
              </>
            )}
          </Typography>

          {/* mobile - title: 2 lines */}
          <Box
            sx={{
              display: { xs: 'flex', md: 'none' },
              marginBottom: '14px',
              flexDirection: 'column'
            }}
          >
            <Typography variant="h6">{title}</Typography>
            {minutesType && <Typography variant="h6">{minutesType}</Typography>}
          </Box>

          <Typography
            data-cy="topic-minutes-detail-card_minutes-title"
            sx={{ marginBottom: '14px', fontSize: '18px' }}
            variant="body1"
          >
            {minute?.label}
          </Typography>

          {/* DATE */}
          <Stack key="date" sx={{ flexDirection: 'row', alignItems: 'center' }}>
            {!isPrintVersion && (
              <CalendarTodayIcon color="action" sx={{ mr: { xs: 0.5, md: 2 }, opacity: 1 }} />
            )}
            <Typography variant="body2" sx={{ display: 'inline' }}>
              <DateTimeStartEnd
                isoStringDate={minute?.startDateTime}
                dateFormat="DATE_HUGE"
                separator={` ${t('common:labelFrom2')} `}
                isoStringStart={minute?.startDateTime}
                isoStringEnd={minute?.endDateTime}
              />
            </Typography>
            {minute?.location && minute?.location?.type === 'remote' && (
              <>
                <Box sx={{ mx: 4 }}>&#183;</Box>
                <Typography key="locationType" variant="body2">
                  {t(`common:locationType.${minute.location?.type}`)}
                </Typography>
              </>
            )}
          </Stack>

          {/* ADDRESS */}
          <Stack
            sx={{
              flexDirection: { xs: 'colum', sm: 'row' },
              alignItems: { xs: 'left', sm: 'center' },
              mt: { xs: 1, md: 0 }
            }}
          >
            {minute?.location?.address && (
              <>
                <Box sx={{ whiteSpace: 'pre-wrap', display: 'flex', alignItems: 'center' }}>
                  {!isPrintVersion && (
                    <LocationOnIcon color="action" sx={{ mr: { xs: 0.5, md: 2 }, opacity: 1 }} />
                  )}
                  <Typography variant="body2">{minute?.location?.address}</Typography>
                </Box>

                <Box sx={{ display: { xs: 'none', sm: 'block' }, mx: 4, my: 1 }}>&#183;</Box>
              </>
            )}

            {minute?.location && minute?.location?.type !== 'remote' && (
              <Typography key="locationType" variant="body2" sx={{ ml: { xs: 3.5, sm: 0 } }}>
                {t(`common:locationType.${minute.location?.type}`)}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Paper>
    </>
  );
};
