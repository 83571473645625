import {
  useLocale,
  useParamsKey,
  useComponentVisibility,
  useUserShow,
  useResourceCreate,
  useWorkspaceShow
} from '@koopajs/react';
import {
  Typography,
  Box,
  Stack,
  Paper,
  Button,
  Breadcrumbs,
  Link,
  IconButton,
  Tooltip,
  Divider,
  ListItem,
  ListItemText
} from '@mui/material';
import { Helmet } from 'react-helmet';
import { IResolution } from 'types';
import { Link as RouterLink } from 'react-router-dom';
import { DocumentList } from 'components/DocumentList';
import { useState, useCallback, useRef } from 'react';
import {
  Edit as EditIcon,
  WarningAmber as WarningAmberIcon,
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material';
import { getCurrentUserRoles } from 'utils/getCurrentUserRoles';
import { ParticipantsList } from 'components/ParticipantsList';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { ResolutionVisibleByAccordion } from 'components/WrittenResolution/ResolutionVisibleByAccordion';
import { EventsTimeline } from 'components/EventsTimeline';
import { SignatureList } from 'components/Signature/SignatureList';
import { isCustomResNumFormat } from 'utils/isCustomResNumFormat';
import { ValidateResolutionButton } from 'components/WrittenResolution/ValidateResolutionButton';
import { ResolutionEditForm } from 'components/WrittenResolution/ResolutionEditForm';
import { DeleteResolution } from 'components/Modals/DeleteResolution';
import { DeclineResolution } from 'components/Modals/DeclineResolution';
import { EditAttachedResolutionModal } from 'components/Modals/EditAttachedResolutionModal';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { generateResolutionTimeline } from 'utils/generateResolutionTimeline';
import { useSnackbar } from 'notistack';
import { Close as CloseIcon } from '@mui/icons-material';
import { RenderPrintButton } from 'components/RenderPrintButton';
import { ViewResolutionPrintVersion } from 'components/Dialogs/ViewResolutionPrintVersion';
import { getSignaturesWithTitles } from 'utils/getSignaturesWithTitles';
import { PageContainer } from 'components/temp/PageContainer';
import { ErrorMessage } from '@koopajs/mui';

interface IWrittenResolutionProps {
  resolution: IResolution;
}

export const WrittenResolution: React.FC<IWrittenResolutionProps> = (props) => {
  const { resolution } = props;

  const { t } = useLocale();
  const keyPrefix = 'WrittenResolution';
  const { workspace } = useWorkspaceShow();
  const printComponentRef = useRef(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const sendResolutionDialog = useComponentVisibility('sendResolution');

  const [isEditable, setIsEditable] = useState(false);

  const [isEditAttachedResolutionModalOpen, setIsEditAttachedResolutionModalOpen] = useState(false);

  const handleCloseEditAttachedResolutionModal = useCallback(() => {
    setIsEditAttachedResolutionModalOpen(false);
  }, []);

  const handleSetIsEditable = useCallback(
    (value: boolean, options?: { isWarningModal?: boolean }) => {
      if (!value || options?.isWarningModal) {
        setIsEditable(value);
        return;
      }

      if (resolution.state === 'attached') {
        setIsEditAttachedResolutionModalOpen(true);
        return;
      }

      setIsEditable(value);
    },
    [resolution.state]
  );

  const { user } = useUserShow();
  const resolutionId = useParamsKey('id');
  const { user: currentUser } = useUserShow();

  const isResolutionDraft = resolution?.state === 'draft';
  const isResolutionSealed = resolution?.state === 'sealed';
  const isResolutionSent = resolution?.state === 'attached';

  const [isDeleteResolutionOpen, setIsDeleteResolutionOpen] = useState(false);
  const [isDeclineResolutionOpen, setIsDeclineResolutionOpen] = useState(false);

  const handleOpenDeleteModal = useCallback(() => {
    setIsDeleteResolutionOpen(true);
  }, []);

  const handleCloseDeleteModal = useCallback(() => {
    setIsDeleteResolutionOpen(false);
  }, []);

  const handleOpenDeclineModal = useCallback(() => {
    setIsDeclineResolutionOpen(true);
  }, []);

  const handleCloseDeclineModal = useCallback(() => {
    setIsDeclineResolutionOpen(false);
  }, []);

  const { createResource: handleAddVote } = useResourceCreate({
    path: `/resolutions/${resolutionId}/vote`,
    customReducer: {
      path: {
        resourceType: '/resolutions',
        resourceId: resolutionId
      },
      mapping: (prevObj: unknown, newObj: unknown) => {
        return newObj as IResolution;
      }
    }
  });

  const isCurrentUserOwner = currentUser?.id === resolution?.$ownedBy;

  const isUserWrittenResolutionCreatorRole = getCurrentUserRoles({
    activeCommitteeMembers: resolution?.activeCommitteeMembers,
    userId: currentUser?.id
  }).includes('createResolutionProjects');

  const userHasEditAccess = (isCurrentUserOwner || isUserWrittenResolutionCreatorRole) && !isResolutionSealed;

  const isUserAbleToSign =
    resolution?.state === 'attached' &&
    user &&
    resolution.membersLeftToSign?.map((member) => member.userId).includes(user?.id);

  const isCustomResNum = isCustomResNumFormat(resolution?.committeeId as string);
  const committeeType = useCommitteeName(resolution.committeeId as string);

  const [hasClickedSendResolution, setHasClickedSendResolution] = useState(false);

  const isMissingRecipients =
    !resolution.attachedToEmail?.recipientIds || resolution.attachedToEmail.recipientIds.length === 0;

  const handleShowSendForAdoptionDialog = useCallback(() => {
    setHasClickedSendResolution(true);

    if (isMissingRecipients) return;

    // reset clicked send resolution state
    setHasClickedSendResolution(false);
    setIsEditable(false);
    sendResolutionDialog.setVisibleWithContext({
      resolution,
      defaultValues: {
        subject: t('Dialogs.DialogSendResolution.DialogFormSend.subjectPrefix', { title: resolution?.title })
      },
      isCustomResNum
    });
  }, [JSON.stringify(resolution), isCustomResNum, isMissingRecipients]);

  const handleAdoptAndSign = useCallback(async () => {
    await handleAddVote({}, (resourceId, resource) => {
      const resolution = resource as IResolution;

      if (resolution.signaturesCompletedAt && resolution?.resolutionOutcome?.outcome === 'approved') {
        enqueueSnackbar(t(keyPrefix + '.snackbarResolutionAdoptionsCompleted'), {
          variant: 'success',
          action: (key) => (
            // eslint-disable-next-line react/jsx-no-bind
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
          )
        });
      }
    });
  }, [resolutionId]);

  const hasUserAlreadyReviewed =
    currentUser && resolution?.reviews?.map((signature) => signature.userId).includes(currentUser?.id);

  const renderActionButtons = (): React.ReactNode => {
    return (
      <>
        {userHasEditAccess && (
          <>
            {isResolutionDraft && (
              <ValidateResolutionButton resolutionId={resolution.id} isRevalidate={hasUserAlreadyReviewed} />
            )}
            {isResolutionSent ? (
              <Button
                variant="outlined"
                onClick={handleShowSendForAdoptionDialog}
                data-cy="resolution-review_send-button"
              >
                {t(keyPrefix + '.resendForAdoptionLabel')}
              </Button>
            ) : (
              <Tooltip title={isMissingRecipients ? t(keyPrefix + '.tooltipMissingSignatories') : ''}>
                <Button
                  variant="contained"
                  onClick={handleShowSendForAdoptionDialog}
                  data-cy="resolution-review_send-button"
                >
                  {t(keyPrefix + '.sendForAdoptionLabel')}
                </Button>
              </Tooltip>
            )}
          </>
        )}
        {isUserAbleToSign && (
          <Button
            variant="contained"
            onClick={handleAdoptAndSign}
            data-cy="resolution-review_adopt-sign-button"
          >
            {t(keyPrefix + '.adoptAndSignLabel')}
          </Button>
        )}
      </>
    );
  };

  const timelineEvents = generateResolutionTimeline(resolution);

  const uniqueUserReviewsCount = resolution.reviewers?.filter((reviewers) => reviewers.latestReview).length;

  const signaturesWithTitles = getSignaturesWithTitles(resolution);

  const renderHeaderTitle = (): string => {
    if (userHasEditAccess && isResolutionDraft) {
      return t(keyPrefix + '.titleReview');
    } else if (isUserAbleToSign) {
      return t(keyPrefix + '.titleSign');
    } else {
      return t(keyPrefix + '.titleView');
    }
  };
  return (
    <PageContainer sxChildren={{ padding: '24px' }} className="rr-block">
      <Helmet>
        <title>{`${renderHeaderTitle()} - Panorama`}</title>
      </Helmet>

      <Breadcrumbs aria-label="breadcrumb" sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Link
          underline="hover"
          color="inherit"
          to={isResolutionDraft ? '/pending-review' : '/to-sign'}
          sx={{ cursor: 'pointer' }}
          component={RouterLink}
        >
          {t('common:navigation.dashboard')}
        </Link>
        <Typography color="text.primary"> {renderHeaderTitle()}</Typography>
      </Breadcrumbs>
      <Box sx={{ display: 'none' }}>
        <ViewResolutionPrintVersion resolution={resolution} ref={printComponentRef} />
      </Box>
      <Box sx={{ mx: { lg: 9 } }}>
        {/* TITLE AND BUTTONS */}
        <Box sx={{ mt: { sm: 4 }, mb: 2 }} data-cy="resolution-review_header">
          <Stack
            flexDirection={{ xs: isResolutionDraft ? 'column-reverse' : 'column', sm: 'row' }}
            justifyContent="space-between"
          >
            {/* title + mobile back */}
            <Stack flexDirection="row" alignItems="center">
              <Button
                component={RouterLink}
                to={isResolutionDraft ? '/pending-review' : '/to-sign'}
                variant="outlined"
                sx={{ minWidth: 0, px: '5px', display: { sm: 'none' }, mr: '14px' }}
                aria-label={t('common:labelBack')}
              >
                <ArrowBackIcon sx={{ color: 'primary.main' }} />
              </Button>
              <Typography
                variant="h1"
                component="h1"
                sx={{ display: 'inline-block', fontWeight: 500, fontSize: '20px', mr: 'auto' }}
              >
                {renderHeaderTitle()}
              </Typography>
            </Stack>

            {/* validations */}
            {isResolutionDraft ? (
              <Typography variant="body2" sx={{ textAlign: 'right', opacity: 0.6, mb: { xs: 1, sm: 0 } }}>
                {t(keyPrefix + '.reviewCountOfTotal', {
                  reviewsMade: uniqueUserReviewsCount,
                  reviewsPossible: resolution.reviewers?.length
                })}
              </Typography>
            ) : (
              <Stack
                flexDirection={{ xs: 'column', sm: 'row' }}
                justifyContent="flex-end"
                gap={{ xs: 2, sm: 3 }}
                sx={{ mt: { xs: 3, sm: 1 } }}
              >
                {renderActionButtons()}
              </Stack>
            )}
          </Stack>

          {/* buttons */}
          {isResolutionDraft && (
            <Stack
              flexDirection={{ xs: 'column', sm: 'row' }}
              justifyContent="flex-end"
              gap={{ xs: 2, sm: 3 }}
              sx={{ mt: { xs: 3, sm: 1 } }}
            >
              {renderActionButtons()}
            </Stack>
          )}
        </Box>

        {/* EDIT MODAL */}
        {userHasEditAccess && (
          <EditAttachedResolutionModal
            resolutionId={resolutionId}
            isOpen={isEditAttachedResolutionModalOpen}
            onClose={handleCloseEditAttachedResolutionModal}
            handleSetIsEditable={handleSetIsEditable}
          />
        )}

        {/* MAIN CARD */}
        <Paper sx={{ padding: 3, gap: 1, mt: 2 }} variant="outlined">
          <ResolutionVisibleByAccordion
            resolution={resolution}
            isUserWrittenResolutionCreatorRole={isUserWrittenResolutionCreatorRole}
          />

          <Divider sx={{ my: 2 }} />
          {isEditable && userHasEditAccess ? (
            <ResolutionEditForm
              resolution={resolution}
              handleSetIsEditable={handleSetIsEditable}
              showMissingRecipientsError={
                hasClickedSendResolution && isMissingRecipients && isResolutionDraft
              }
            />
          ) : (
            <Box
              sx={{
                flexGrow: 1
              }}
            >
              <Stack gap={3}>
                {/* 
                    written resolution | committee type  -   edit button
                  */}
                <Stack
                  flexDirection="row"
                  alignItems={{ xs: 'flex-start', sm: 'center' }}
                  justifyContent="space-between"
                >
                  <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
                    <Box sx={{ display: { xs: 'block', sm: 'inline' }, mb: { xs: 1, sm: 0 } }}>
                      {t('common:writtenResolution')}
                    </Box>

                    <Typography
                      sx={{ fontSize: '18px', fontWeight: 500, display: { xs: 'none', sm: 'inline' } }}
                      component="span"
                    >
                      {' | '}
                    </Typography>
                    <Box sx={{ display: { xs: 'block', sm: 'inline' } }}>{committeeType}</Box>
                  </Typography>
                  {userHasEditAccess && (
                    <Tooltip title={t('common:labelEdit')}>
                      <IconButton
                        onClick={() => handleSetIsEditable(true)}
                        data-cy="resolution-review_edit-resolution-button"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>

                {/* 
                  workspace name
                 */}
                {workspace && <Typography>{workspace.name}</Typography>}

                {/* 
                  resolution number - title
                 */}
                <Stack flexDirection={{ sm: 'row' }} alignItems={{ sm: 'center' }} gap={{ xs: 1, sm: 3 }}>
                  {resolution.resolutionNumber && (
                    <>
                      <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
                        #{resolution.resolutionNumber}
                      </Typography>
                      <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>&#183;</Typography>
                    </>
                  )}
                  {/* show pending text if resolution is not adopted or archived */}
                  {!resolution.resolutionOutcome &&
                    !resolution.resolutionNumber &&
                    !resolution?.archivedAt &&
                    !isCustomResNum && (
                      <>
                        <Tooltip title={t(keyPrefix + '.resolutionPendingTooltip')}>
                          <Typography sx={{ opacity: '0.38' }}>
                            # {t(keyPrefix + '.resolutionPending')}
                          </Typography>
                        </Tooltip>
                        <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>&#183;</Typography>
                      </>
                    )}
                  {userHasEditAccess &&
                    isCustomResNum &&
                    !resolution.resolutionOutcome &&
                    !resolution.resolutionNumber &&
                    !resolution?.archivedAt && (
                      <>
                        <Link
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            color: 'link.main',
                            textTransform: 'none',
                            fontSize: '1rem',
                            fontWeight: '400',
                            textDecoration: 'none'
                          }}
                          onClick={() => handleSetIsEditable(true)}
                          component="button"
                        >
                          <WarningAmberIcon sx={{ mr: 1 }} />
                          {t(keyPrefix + '.customResolutionNumberWarning')}
                        </Link>{' '}
                        <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>&#183;</Typography>
                      </>
                    )}

                  <Typography
                    data-cy="resolution-review_resolution-title"
                    sx={{ fontSize: '18px', fontWeight: 500 }}
                  >
                    {resolution?.title}
                  </Typography>
                </Stack>

                {resolution?.notes && <RichTextReadOnly value={resolution.notes} />}
              </Stack>

              {isResolutionDraft ? (
                <>
                  <ListItem
                    alignItems="flex-start"
                    sx={{ flexDirection: 'column', px: 0, pb: 0, mt: 1 }}
                    component="div"
                  >
                    {hasClickedSendResolution && isMissingRecipients && (
                      <ErrorMessage
                        sx={{ width: '100%', my: 1 }}
                        error={t(keyPrefix + '.errorNoRecipients')}
                        data-cy="resolution-review_missing-signatures-error"
                      />
                    )}
                    <ListItemText
                      primary={t('common:signatories')}
                      primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                      secondary={
                        resolution?.attachedToEmail?.recipientIds?.length ? (
                          <ParticipantsList
                            participants={
                              resolution?.attachedToEmail?.recipientIds?.map((id) => ({ userId: id })) || []
                            }
                            sx={{ mb: 0 }}
                          />
                        ) : (
                          <Link
                            sx={{
                              display: 'inline',
                              cursor: 'pointer',
                              color: 'link.main',
                              textTransform: 'none',
                              fontSize: '1rem',
                              fontWeight: '400',
                              textDecoration: 'none'
                            }}
                            onClick={() => handleSetIsEditable(true)}
                          >
                            {t(keyPrefix + '.selectSignatories')}
                          </Link>
                        )
                      }
                    />
                  </ListItem>
                </>
              ) : (
                <>
                  {Boolean(resolution?.membersLeftToSign?.length) && (
                    <>
                      <Divider sx={{ my: 2 }} />
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <WarningAmberIcon sx={{ opacity: '0.6', mr: 0.5 }} fontSize="small" />
                        <Typography data-cy="resolution-review_missing-signatures">
                          {t(keyPrefix + '.missingSignatures', {
                            count: resolution?.membersLeftToSign?.length
                          })}
                        </Typography>
                      </Box>
                      <ParticipantsList
                        participants={resolution?.membersLeftToSign?.map((m) => ({ userId: m.userId })) || []}
                      />
                      <Typography variant="body2" sx={{ opacity: '0.6' }}>
                        {t(keyPrefix + '.resolutionWillBeAdopted')}
                      </Typography>
                    </>
                  )}

                  {signaturesWithTitles && (
                    <Box>
                      <Divider sx={{ my: 2 }} />
                      <SignatureList signatures={signaturesWithTitles} />
                    </Box>
                  )}
                </>
              )}

              {resolution?.documentsIds && resolution?.documentsIds?.length > 0 && (
                <>
                  <Divider sx={{ my: 2 }} />
                  <DocumentList
                    path={`/resolutions/${resolutionId}/documents`}
                    isAccordion
                    accordionOptions={{
                      defaultExpanded: true,
                      documentsVisible: 0
                    }}
                  />
                </>
              )}
            </Box>
          )}
        </Paper>

        {/* TIMELINE */}
        <Box sx={{ mt: 3 }}>
          <EventsTimeline timelineEvents={timelineEvents} />
        </Box>

        {/* FOOTER */}
        <Stack
          justifyContent="space-between"
          alignItems="flex-start"
          flexDirection={{ xs: 'column-reverse', sm: 'row' }}
          gap={2}
        >
          <Stack
            flexDirection="row"
            gap={2}
            alignItems="center"
            justifyContent={{ xs: 'space-between' }}
            width={{ xs: '100%', sm: 'auto' }}
          >
            {/* mobile back */}
            <Button
              component={RouterLink}
              to={isResolutionDraft ? '/pending-review' : '/to-sign'}
              variant="outlined"
              sx={{ minWidth: 0, px: '5px', display: { sm: 'none' } }}
              aria-label={t('common:labelBack')}
            >
              <ArrowBackIcon sx={{ color: 'primary.main' }} />
            </Button>
            {userHasEditAccess && (
              <>
                {isResolutionDraft ? (
                  <>
                    <DeleteResolution
                      id={resolution?.id}
                      isOpen={isDeleteResolutionOpen}
                      onClose={handleCloseDeleteModal}
                    />
                    <Button
                      onClick={handleOpenDeleteModal}
                      color="error"
                      data-cy="resolution-review_delete-resolution-button"
                      aria-label={t(keyPrefix + '.labelDeleteResolution')}
                    >
                      {t(keyPrefix + '.labelDeleteResolution')}
                    </Button>
                  </>
                ) : (
                  <>
                    <DeclineResolution
                      id={resolution?.id}
                      isOpen={isDeclineResolutionOpen}
                      onClose={handleCloseDeclineModal}
                    />
                    <Button
                      onClick={handleOpenDeclineModal}
                      color="error"
                      sx={{ ml: { xs: 'auto', sm: 0 } }}
                      data-cy="resolution-review_decline-resolution-button"
                    >
                      {t(keyPrefix + '.labelDeclineResolution')}
                    </Button>
                  </>
                )}
              </>
            )}

            {/* mobile print */}
            {isResolutionSealed && (
              <RenderPrintButton
                componentRef={printComponentRef}
                variant="outlined"
                sx={{ display: { sm: 'none' } }}
              />
            )}
          </Stack>

          <Stack
            direction="row"
            gap={2}
            alignItems="flex-start"
            sx={{ ml: { sm: 'auto' }, width: { xs: '100%', sm: 'auto' } }}
          >
            {/* desktop back */}
            <Button
              component={RouterLink}
              to={isResolutionDraft ? '/pending-review' : '/to-sign'}
              variant={userHasEditAccess ? undefined : 'contained'}
              sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
            >
              {t('common:labelBack')}
            </Button>
            <Stack
              flexDirection={{ xs: 'column', sm: 'row' }}
              gap={{ xs: 2, sm: 3 }}
              sx={{ mt: { xs: 3, sm: 0 }, width: { xs: '100%', sm: 'auto' } }}
            >
              {renderActionButtons()}

              {/* desktop print */}
              {isResolutionSealed && (
                <RenderPrintButton
                  componentRef={printComponentRef}
                  variant="outlined"
                  sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </PageContainer>
  );
};
