import { useLocale, useResourceList, useResourceUpdate } from '@koopajs/react';
import { Box, Stack, Button, InputAdornment } from '@mui/material';
import { IResolution } from 'types';
import { FormControllerTemp } from 'components/temp/FormControllerTemp';
import { TextField } from 'components/temp/TextFieldTemp';
import { DocumentList } from 'components/DocumentList';
import { useCallback, useMemo } from 'react';
import RichTextMultiLine from 'components/temp/RichTextMultiLine';
import { isCustomResNumFormat } from 'utils/isCustomResNumFormat';
import { useCommitteeName } from 'components/hooks/useCommitteeName';

import { ErrorMessage, Form } from '@koopajs/mui';
import { ParticipantsField } from 'components/ParticipantsField';
import { IUserPublicProfile } from '@koopajs/app';
import { processUsersForInput } from 'utils/processUsersForInput';

interface IResolutionEditFormProps {
  resolution: IResolution;
  handleSetIsEditable: (value: boolean, options?: { isWarningModal?: boolean }) => void;
  showMissingRecipientsError?: boolean;
}

export const ResolutionEditForm: React.FC<IResolutionEditFormProps> = (props) => {
  const { resolution, handleSetIsEditable, showMissingRecipientsError } = props;

  const { t } = useLocale();
  const keyPrefix = 'WrittenResolution';

  const { resources: users } = useResourceList<IUserPublicProfile>({ path: '/users' });

  const isCustomResNum = isCustomResNumFormat(resolution.committeeId as string);
  const committeeType = useCommitteeName(resolution.committeeId as string);

  const { updateResource, errorMessage } = useResourceUpdate({
    path: '/resolutions',
    id: resolution.id
  });

  const defaultParticipants = useMemo(() => {
    return processUsersForInput({
      users,
      selectedUserIds: resolution.attachedToEmail?.recipientIds?.map((id) => id)
    });
  }, [users.length, resolution.attachedToEmail?.recipientIds?.length]);

  const handleSubmit = useCallback(
    async (formData: object): Promise<boolean> => {
      const res = await updateResource(formData);

      if (res) {
        handleSetIsEditable(false);
      }

      return res;
    },
    [resolution.id, resolution?.state]
  );

  return (
    <FormControllerTemp
      onSubmit={handleSubmit}
      onSubmitSuccessResetType="FORM_PAYLOAD"
      defaultValues={{
        title: resolution?.title,
        notes: resolution?.notes,
        resolutionNumber: resolution?.resolutionNumber,
        committeeId: resolution?.committeeId
      }}
    >
      <ErrorMessage error={errorMessage} />
      <Stack spacing={1}>
        <Stack direction={{ xs: 'column-reverse', sm: 'row' }} alignItems="flexStart" spacing={3}>
          <Form.Select
            name="committeeId"
            i18n={{ keyPrefix: keyPrefix + '.Form.FieldCommitteeType' }}
            options={[{ id: resolution?.committeeId as string, label: committeeType as string }]}
            isDisabled
          />

          <Stack direction="row" alignItems={{ xs: 'flex-end', sm: 'flex-start' }} sx={{ ml: 2 }}>
            <Button
              type="submit"
              variant="contained"
              data-cy="resolution-review_save-resolution-button"
              sx={{ ml: 'auto' }}
            >
              {t('common:labelSave')}
            </Button>
          </Stack>
        </Stack>
        <Box>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} sx={{ my: 2 }}>
            {isCustomResNum && (
              <TextField
                i18n={{ keyPrefix: keyPrefix + '.Form.FieldResolutionNumber' }}
                name="resolutionNumber"
                validationRules={{ maxLength: 250 }}
                isOptional
                sx={{ width: { sm: '200px' }, flex: 'none' }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">#</InputAdornment>
                }}
              />
            )}
            <TextField
              name="title"
              validationRules={{ maxLength: 100 }}
              i18n={{ keyPrefix: keyPrefix + '.Form.FieldTitle' }}
            />
          </Stack>

          <RichTextMultiLine
            name="notes"
            isOptional={true}
            validationRules={{ maxLength: 20000 }}
            height="unset"
            minHeight={`${4 * 23 + 16.5 * 2}px`} // (rows * 23px) + (padding * 2)
            maxHeight={`${20 * 23 + 16.5 * 2}px`}
            i18n={{ keyPrefix: keyPrefix + '.Form.FieldNotes' }}
            isLabelVisible
          />
        </Box>
        <DocumentList path={`/resolutions/${resolution.id}/documents`} isEditable isAccordion />

        {showMissingRecipientsError && (
          <ErrorMessage sx={{ width: '100%', my: 1 }} error={t(keyPrefix + '.errorNoRecipients')} />
        )}

        <ParticipantsField
          name="attachedToEmail.recipientIds"
          users={users}
          committeeId={resolution?.committeeId}
          defaultParticipants={defaultParticipants}
          keyPrefix={keyPrefix + '.Form'}
          label={t('common:signatories')}
          testId="resolution-review_recipients-field"
          isOptional
        />
      </Stack>
    </FormControllerTemp>
  );
};
