import { IResolution } from 'types';
import { useCallback } from 'react';
import { Stack, Typography, Box, Button, Tooltip, Chip, Paper } from '@mui/material';
import { Person as PersonIcon } from '@mui/icons-material';
import { useLocale } from '@koopajs/react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { IUser } from '@koopajs/app';
import { UserList } from 'components/UserList';
import { Link as RouterLink } from 'react-router-dom';
import { LatestReviewsByUser } from './ToReview/LatestReviewsByUser';
import { useCommitteeName } from 'components/hooks/useCommitteeName';

export const i18nTransComponents: { [k: string]: React.ReactElement } = { span: <span /> };

interface IResolutionPendingReviewCardProps {
  resolution: IResolution;
  user?: IUser;
}

export const ResolutionPendingReviewCard: React.FC<IResolutionPendingReviewCardProps> = (props) => {
  const { resolution, user } = props;

  const { t } = useLocale();
  const keyPrefix = 'Dashboard.ResolutionPendingReviewCard';

  const usersWhoReviewed = resolution?.reviews?.map((review) => review.userId) || [];
  const hasCurrentUserReviewed = usersWhoReviewed.includes(user?.id || '');
  const committeeType = useCommitteeName(resolution?.committeeId as string);

  const missingReviewersIds =
    resolution?.reviewers
      ?.filter((reviewer) => !reviewer.latestReview)
      .map((missingReviewer) => missingReviewer.userId) || [];

  const renderResolutionActionButton = useCallback(() => {
    return (
      <Button
        variant={hasCurrentUserReviewed ? 'text' : 'contained'}
        component={RouterLink}
        // sx={{ whiteSpace: 'nowrap' }}
        data-cy="resolution-pending-review-card_review-button"
        to={{
          pathname: `/resolutions/${resolution.id}`,
          state: { activePath: '/pending-review', activeRootPage: '' }
        }}
      >
        {t(`${keyPrefix}.labelReviewResolution`)}
      </Button>
    );
  }, [JSON.stringify(resolution.reviews)]);

  return (
    <Stack
      component={Paper}
      variant="outlined"
      sx={{ padding: '24px', borderRadius: '4px' }}
      data-cy="resolution-pending-review-card"
    >
      {/* title & button */}
      <Stack
        direction={{ xs: 'column-reverse', sm: 'row' }}
        sx={{ justifyContent: 'space-between', alignItems: 'baseline' }}
        gap={{ xs: 2, sm: 0 }}
      >
        <Typography sx={{ marginBottom: '14px', fontSize: '16px' }} variant="h3">
          {resolution?.title}
        </Typography>
        <Chip
          label={t('common:resolution')}
          sx={{ mr: 'auto', ml: { sm: 6 }, backgroundColor: 'greenOne.30' }}
        />

        {/* People who can review & approve the minutes */}
        <Box sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'column', alignItems: 'flex-end' }}>
          {renderResolutionActionButton()}
        </Box>
      </Stack>
      {/* details */}
      <Box
        sx={{
          opacity: '0.6',
          mb: 2
        }}
      >
        <Stack
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          gap={{ xs: 0.5, sm: 2 }}
        >
          <Typography variant="body2">{t('common:writtenResolution')}</Typography>

          <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>&#183;</Typography>
          <Typography variant="body2">{committeeType}</Typography>
        </Stack>
      </Box>
      {/* Display people who have reviewed */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { md: 'space-between' },
          alignItems: 'baseline'
        }}
      >
        <Box sx={{ mb: { xs: 1, md: 0 } }}>
          <LatestReviewsByUser reviewers={resolution.reviewers} />
        </Box>
        {/* Display reviewers who haven't review yet */}
        {missingReviewersIds && missingReviewersIds.length > 0 && (
          <Tooltip title={<UserList userIds={missingReviewersIds} icon={<PersonIcon color="action" />} />}>
            <Box sx={{ maxWidth: {}, display: 'flex', justifyContent: 'flex-start' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
                <WarningAmberIcon sx={{ opacity: '0.6', mr: 0.5 }} fontSize="small" />
                <Typography variant="body2" sx={{ opacity: '0.6' }}>
                  {t(`${keyPrefix}.approvalsPending`, { count: missingReviewersIds?.length })}
                </Typography>
              </Box>
            </Box>
          </Tooltip>
        )}
      </Box>
      <Box
        sx={{ display: { xs: 'flex', md: 'none' }, flexDirection: 'column', alignItems: 'flex-start', mt: 2 }}
      >
        {renderResolutionActionButton()}
      </Box>
    </Stack>
  );
};
