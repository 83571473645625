import { useRef, useCallback } from 'react';
import { IResolution } from 'types';
import { Box, Typography, Paper, Divider } from '@mui/material';
import { Dialog, Loading } from '@koopajs/mui';
import { ResolutionOutcomeText } from 'components/ResolutionOutcome/ResolutionOutcomeText';
import { DocumentList } from '../DocumentList';
import { RenderPrintButton } from '../RenderPrintButton';
import { ViewResolutionPrintVersion } from './ViewResolutionPrintVersion';

import { SignatureList } from 'components/Signature/SignatureList';
import { useHistory } from 'react-router-dom';
import { ResolutionOutcomeIcon } from 'components/ResolutionOutcome/ResolutionOutcomeIcon';
import { ResolutionOutcomeVotes } from 'components/ResolutionOutcome/ResolutionOutcomeVotes';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { useLocale } from '@koopajs/react';
import { isEmpty } from 'lodash';
import { TopicMinutesDetailsCard } from 'components/TopicMinutesDetailsCard';
import { WrittenResolutionDialogDetailsCard } from 'components/Resolutions/WrittenResolutionDialogDetailsCard';
import { generateResolutionTimeline } from 'utils/generateResolutionTimeline';
import { EventsTimeline } from 'components/EventsTimeline';
import { getSignaturesWithTitles } from 'utils/getSignaturesWithTitles';

interface IViewResolutionProps {
  resolution?: IResolution;
  isResolutionProcessing?: boolean;
}

export const ViewResolution: React.FC<IViewResolutionProps> = (props) => {
  const { resolution, isResolutionProcessing } = props;
  const keyPrefix = 'Resolutions.DialogView';
  const history = useHistory();
  const printComponentRef = useRef(null);
  const { t } = useLocale();

  const isAttachedToMeetingTopic = Boolean(resolution?.attachedToMeetingTopic);
  const isAttachedToEmail = Boolean(resolution?.attachedToEmail);

  const { membersAgainst, membersAbstained, membersFor } = resolution?.resolutionOutcome || {};
  const showResolutionOutcomeVotes = membersFor?.length || membersAbstained?.length || membersAgainst?.length;

  const handleOnClose = useCallback(() => {
    history.push({ pathname: `/resolutions`, search: history.location?.search });
  }, []);

  const timelineEvents = generateResolutionTimeline(resolution);

  const signaturesWithTitles = getSignaturesWithTitles(resolution);

  if (!resolution) return null;

  return (
    <Dialog.View
      isOpen={Boolean(resolution)}
      onClose={handleOnClose}
      isCloseVisible={true}
      i18n={{ keyPrefix: keyPrefix }}
      footerActions={
        <RenderPrintButton keyPrefix={keyPrefix} componentRef={printComponentRef} size="small" />
      }
      dialogProps={{ className: 'rr-block' }}
    >
      <Box sx={{ display: 'none' }}>
        <ViewResolutionPrintVersion
          resolution={resolution}
          ref={printComponentRef}
          // minutes={minutes}
        />
      </Box>

      {isResolutionProcessing ? (
        <Box sx={{ my: 3 }}>
          <Loading sx={{ backgroundColor: 'transparent' }} />
        </Box>
      ) : (
        <Box>
          <>
            {isAttachedToMeetingTopic && resolution.attachedToMeetingTopic?.minuteId && (
              <TopicMinutesDetailsCard
                minuteId={resolution.attachedToMeetingTopic.minuteId}
                title={t('Resolutions.viewResolutionDialogTitle')}
              />
            )}

            {resolution.attachedToEmail && <WrittenResolutionDialogDetailsCard resolution={resolution} />}

            <Paper sx={{ p: 3, my: 2 }} variant="outlined">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  alignItems: 'baseline',
                  mb: 2
                }}
              >
                {resolution?.resolutionNumber && (
                  <>
                    <Typography
                      variant="h6"
                      sx={{ display: 'inline-block', fontWeight: 500, mb: { xs: 1, md: 0 } }}
                      data-cy="view-resolution-dialog_resolution-number"
                    >
                      #{resolution.resolutionNumber}
                    </Typography>
                    <Typography sx={{ mx: 3, display: { xs: 'none', md: 'block' } }}>&#183;</Typography>
                  </>
                )}
                <Typography variant="body1" data-cy="view-resolution-dialog_resolution-title">
                  {resolution.title
                    ? resolution.title
                    : resolution.attachedToMeetingTopic?.type
                    ? t('common:topicTypes.' + resolution.attachedToMeetingTopic.type)
                    : null}
                </Typography>
              </Box>
              <Divider />

              {resolution?.notes && (
                <>
                  <Typography variant="body2" component="div" sx={{ my: 2 }}>
                    <RichTextReadOnly value={resolution.notes} />
                  </Typography>
                </>
              )}

              {isAttachedToMeetingTopic &&
                resolution?.resolutionOutcome &&
                !isEmpty(resolution.resolutionOutcome) && (
                  <>
                    <Typography variant="body1" sx={{ my: 2, display: 'flex', alignItems: 'flex-start' }}>
                      <ResolutionOutcomeIcon voteOutcome={resolution.resolutionOutcome.outcome} />
                      <ResolutionOutcomeText topic={resolution} />
                    </Typography>

                    {showResolutionOutcomeVotes && (
                      <Box sx={{ mb: 2 }}>
                        <ResolutionOutcomeVotes topic={resolution} />
                      </Box>
                    )}
                  </>
                )}
              {resolution?.documentsIds && resolution?.documentsIds?.length > 0 && (
                <>
                  <DocumentList
                    path={`/resolutions/${resolution.id}/documents`}
                    isAccordion={true}
                    accordionOptions={{
                      defaultExpanded: true,
                      documentsVisible: 0
                    }}
                  />
                </>
              )}
              {isAttachedToEmail && resolution.resolutionOutcome?.outcome === 'declined' && (
                <Box
                  sx={{ display: 'flex', alignItems: 'flex-start', mt: 2 }}
                  data-cy="view-resolution-dialog_written-resolution-declined"
                >
                  <ResolutionOutcomeIcon voteOutcome={resolution.resolutionOutcome.outcome} />
                  {t(keyPrefix + '.writtenResolutionSignatureDescriptionDeclined')}
                </Box>
              )}
              {signaturesWithTitles && (
                <Box sx={{ my: 4 }}>
                  <SignatureList
                    signatures={signaturesWithTitles}
                    description={
                      isAttachedToEmail &&
                      resolution.resolutionOutcome?.outcome === 'approved' && (
                        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                          <ResolutionOutcomeIcon voteOutcome={resolution.resolutionOutcome.outcome} />
                          {t(keyPrefix + '.writtenResolutionSignatureDescriptionApproved')}
                        </Box>
                      )
                    }
                  />
                </Box>
              )}
            </Paper>
          </>
        </Box>
      )}
      <Box sx={{ mt: 3 }}>
        <EventsTimeline timelineEvents={timelineEvents} />
      </Box>
    </Dialog.View>
  );
};
