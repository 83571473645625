import { forwardRef } from 'react';
import { IResolution } from 'types';
import { Box, Typography, Paper, Divider } from '@mui/material';
import { ResolutionOutcomeText } from 'components/ResolutionOutcome/ResolutionOutcomeText';
import { SignatureList } from 'components/Signature/SignatureList';
import { useWorkspaceShow } from '@koopajs/react';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';

import { useLocale } from '@koopajs/react';
import { isEmpty } from 'lodash';
import { TopicMinutesDetailsCard } from 'components/TopicMinutesDetailsCard';
import { WrittenResolutionDialogDetailsCard } from 'components/Resolutions/WrittenResolutionDialogDetailsCard';
import { getSignaturesWithTitles } from 'utils/getSignaturesWithTitles';
interface IDialogViewResolutionProps {
  resolution?: IResolution;
  ref: React.ForwardedRef<unknown>;
}

export const ViewResolutionPrintVersion: React.FC<IDialogViewResolutionProps> = forwardRef((props, ref) => {
  const { resolution } = props;
  const { workspace } = useWorkspaceShow();
  const { t } = useLocale();
  const keyPrefix = 'Resolutions.DialogView';

  const isAttachedToMeetingTopic = Boolean(resolution?.attachedToMeetingTopic);
  const isAttachedToEmail = Boolean(resolution?.attachedToEmail);

  const signaturesWithTitles = getSignaturesWithTitles(resolution);

  if (!resolution) return null;

  return (
    <Box ref={ref} className="rr-block">
      <Box sx={{ padding: '24px' }}>
        {/* LOGO (if they have one) */}
        {workspace?.icon && (
          <Box>
            <img
              src={workspace.icon}
              alt="Logo"
              style={{ maxHeight: '50px', maxWidth: '200px', marginTop: '6px', marginLeft: '14px' }}
            />
          </Box>
        )}

        {isAttachedToMeetingTopic && resolution.attachedToMeetingTopic?.minuteId && (
          <TopicMinutesDetailsCard
            minuteId={resolution.attachedToMeetingTopic.minuteId}
            title={t('Resolutions.viewResolutionDialogTitle')}
            isPrintVersion={true}
          />
        )}

        {resolution.attachedToEmail && (
          <WrittenResolutionDialogDetailsCard resolution={resolution} isPrintVersion={true} />
        )}

        <Divider />
        <Paper sx={{ py: 3 }} elevation={0}>
          <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 2.5 }}>
            {resolution?.resolutionNumber && (
              <>
                <Typography variant="h6" sx={{ display: 'inline-block', fontWeight: 500 }}>
                  #{resolution.resolutionNumber}
                </Typography>
                <Typography sx={{ mx: 3 }}>&#183;</Typography>
              </>
            )}
            <Typography variant="body1">
              {resolution.title
                ? resolution.title
                : resolution.attachedToMeetingTopic?.type
                ? t('common:topicTypes.' + resolution.attachedToMeetingTopic.type)
                : null}
            </Typography>
          </Box>

          {resolution?.notes && (
            <>
              <Divider />
              <Typography variant="body2" component="div" sx={{ my: 3 }}>
                <RichTextReadOnly value={resolution.notes} />
              </Typography>
            </>
          )}

          {isAttachedToMeetingTopic &&
            resolution?.resolutionOutcome &&
            !isEmpty(resolution.resolutionOutcome) && (
              <Typography variant="body2" sx={{ my: 3 }}>
                <ResolutionOutcomeText topic={resolution} />
              </Typography>
            )}

          {isAttachedToEmail && resolution.resolutionOutcome?.outcome === 'declined' && (
            <>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ mt: 2 }}>{t(keyPrefix + '.writtenResolutionSignatureDescriptionDeclined')}</Box>
            </>
          )}
          {signaturesWithTitles && (
            <>
              <Divider sx={{ mt: 2 }} />
              <Typography variant="body2" sx={{ my: 2 }}>
                <SignatureList
                  signatures={signaturesWithTitles}
                  description={
                    isAttachedToEmail &&
                    resolution.resolutionOutcome?.outcome === 'approved' && (
                      <Box>{t(keyPrefix + '.writtenResolutionSignatureDescriptionApproved')}</Box>
                    )
                  }
                />
              </Typography>
            </>
          )}
        </Paper>
      </Box>
    </Box>
  );
});
