import { IMinute, ITopic, IMeeting } from 'types';
import { useWorkspaceShow } from '@koopajs/react';
import { Box, Divider } from '@mui/material';
import React, { forwardRef } from 'react';
import { SignatureList } from 'components/Signature/SignatureList';
import { TopicCardMinutesPrint } from 'components/TopicCard/TopicCardMinutesPrint';
import { getSignaturesWithTitles } from 'utils/getSignaturesWithTitles';
import { MinutesHeaderCardPrint } from './MinutesHeaderCardPrint';

interface IViewMinutes {
  minutes?: IMinute | IMeeting;
  topics?: ITopic[];
  ref: React.ForwardedRef<unknown>;
}

export const ViewMinutesPrintVersion: React.FC<IViewMinutes> = forwardRef((props, ref) => {
  const { minutes, topics: approvedTopics } = props;

  const { workspace } = useWorkspaceShow();

  const signaturesWithTitles = getSignaturesWithTitles(minutes);

  return (
    <Box
      className="printWrapper rr-block"
      sx={{
        display: 'block',
        '@media print': {
          '@page': {
            margin: '1in'
          },
          '@page:first': {
            marginTop: '0.7in'
          }
        }
      }}
      ref={ref}
    >
      {/* LOGO (if they have one) */}
      {workspace?.icon && (
        <Box>
          <img src={workspace.icon} alt="Logo" style={{ maxHeight: '50px', maxWidth: '200px' }} />
        </Box>
      )}

      {minutes && <MinutesHeaderCardPrint minutes={minutes} />}

      <Divider sx={{ my: '14px' }} />

      <Box>
        {minutes &&
          approvedTopics?.map((topic, index, array) => {
            return (
              <Box
                key={topic.id}
                sx={{
                  mb: '14px'
                }}
              >
                <TopicCardMinutesPrint topic={topic} order={index + 1} meeting={minutes} />
                {
                  <Divider
                    sx={{
                      mt: '14px',
                      '@media print': {
                        breakAfter: 'auto', // Prevent the box from being the last item
                        breakBefore: 'avoid' // Adjust as needed
                      }
                    }}
                  />
                }
              </Box>
            );
          })}
      </Box>

      {signaturesWithTitles && (
        <Box
          sx={{
            mt: '14px'
          }}
        >
          <SignatureList signatures={signaturesWithTitles} />
        </Box>
      )}
    </Box>
  );
});
