import { IResolution, IMinuteRef } from 'types';
import { Stack, Typography, Box, Button, Tooltip, Skeleton, Chip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { Person as PersonIcon } from '@mui/icons-material';
import { useLocale, User, Ref } from '@koopajs/react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { WarningAmber as WarningAmberIcon } from '@mui/icons-material';
import { DocumentList } from 'components/DocumentList';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import { formatTime } from 'utils/DateTime/formatTime';
import { List, ListItem } from '@mui/material';
import { calculateMissingSignatureCount } from 'utils/calculateMissingSignatureCount';
import { useCommitteeName } from 'components/hooks/useCommitteeName';

interface IToSignItemResolutionProps {
  resolution: IResolution;
  isSignable?: boolean;
}

export const ToSignItemResolution: React.FC<IToSignItemResolutionProps> = (props) => {
  const { resolution, isSignable } = props;

  const { t, locale } = useLocale();
  const keyPrefix = 'ToSign.ToSignItem';

  const isAttachedToEmail = Boolean(resolution.attachedToEmail);

  let buttonLabel = t(`${keyPrefix}.viewResolutionButtonLabel`);

  if (isSignable) {
    buttonLabel = isAttachedToEmail
      ? t(`${keyPrefix}.adoptAndSignResolutionButtonLabel`)
      : t(`${keyPrefix}.signResolutionButtonLabel`);
  }

  const toSignUrl = `/resolutions/${resolution.id}/sign`;

  const committeeType = useCommitteeName(resolution?.committeeId as string);

  return (
    <>
      <Stack direction="row" sx={{ justifyContent: 'space-between', mb: 2, alignItems: 'flex-start' }}>
        <Stack
          direction={{ xs: 'column-reverse', sm: 'row' }}
          alignItems={{ sm: 'center' }}
          gap={{ xs: 2, sm: 0 }}
        >
          <Typography
            sx={{
              fontSize: '18px'
            }}
            variant="h3"
          >
            {resolution.title
              ? resolution.title
              : resolution.attachedToMeetingTopic?.type
              ? t('common:topicTypes.' + resolution.attachedToMeetingTopic.type)
              : null}
          </Typography>
          <Chip
            label={t('common:resolution')}
            sx={{ mr: 'auto', ml: { sm: 6 }, backgroundColor: 'greenOne.30' }}
          />
        </Stack>

        <Box
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-end',
            display: { xs: 'none', md: 'flex' }
          }}
        >
          <Button
            variant={isSignable ? 'contained' : 'text'}
            component={RouterLink}
            data-cy="resolution-to-sign-card_cta"
            sx={{ whiteSpace: 'nowrap' }}
            to={{ pathname: toSignUrl, state: { activePath: '/to-sign', activeRootPage: '' } }}
          >
            {buttonLabel}
          </Button>
        </Box>
      </Stack>
      {resolution.notes && (
        <Stack sx={{ my: 2 }} spacing={1}>
          <>
            {resolution.notes && (
              <Typography variant="body2" component="div">
                <RichTextReadOnly value={resolution.notes} />
              </Typography>
            )}
          </>
        </Stack>
      )}
      <Box sx={{ opacity: '0.6', mt: { xs: 2 } }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'flex-start', md: 'center' },
            gap: { xs: 0.5, md: 2 }
          }}
        >
          {resolution.attachedToEmail && (
            <Box
              sx={{
                display: 'flex',
                gap: { xs: 0.5, md: 2 },
                alignItems: { md: 'center' },
                flexDirection: { xs: 'column', md: 'row' }
              }}
            >
              {/* Minutes type */}

              <Typography variant="body2">{t('common:writtenResolution')}</Typography>
            </Box>
          )}
          {resolution.attachedToMeetingTopic && (
            <Stack direction="row" spacing={2} alignItems="center">
              <CalendarTodayIcon fontSize="small" />
              <Typography variant="body2">
                {resolution.attachedToMeetingTopic.minuteId ? (
                  <Ref content={`ref:minute:${resolution.attachedToMeetingTopic.minuteId}`}>
                    {(item) => {
                      const minutes = item as IMinuteRef;

                      if (!minutes) return <Skeleton variant="text" width={120} />;

                      return (
                        <>
                          {minutes?.startDateTime ? (
                            <DateTimeStartEnd
                              isoStringDate={minutes.startDateTime}
                              dateFormat="DATE_FULL_NO_YEAR"
                              separator={` ${t('common:at')} `}
                              isoStringStart={minutes.startDateTime}
                            />
                          ) : (
                            <i>{t('common:noDate')}</i>
                          )}
                        </>
                      );
                    }}
                  </Ref>
                ) : (
                  <i>{t('common:noDate')}</i>
                )}
              </Typography>
            </Stack>
          )}
          {resolution.resolutionOutcome?.outcome && (
            <>
              <Typography sx={{ mx: 2, display: { xs: 'none', md: 'block' } }}>&#183;</Typography>
              <Typography variant="body2" sx={{ opacity: '0.9' }}>
                {t(`common:TopicModel.resolutionOutcome.outcome.${resolution.resolutionOutcome.outcome}`)}
                {/* show the resolution timestamp for resolution outcome v1 */}
                {resolution.resolutionOutcome.recordedAt && resolution.version === 1 && (
                  <>
                    <span>{t('common:at')} </span>
                    {formatTime({ isoString: resolution.resolutionOutcome.recordedAt, locale })}
                  </>
                )}
              </Typography>
            </>
          )}{' '}
          <Typography sx={{ display: { xs: 'none', md: 'block' } }}>&#183;</Typography>
          <Typography variant="body2">{committeeType}</Typography>
          {resolution.membersLeftToSign && resolution.membersLeftToSign?.length > 0 && (
            <Tooltip
              title={
                <>
                  <List>
                    {resolution.membersLeftToSign?.map((member) => {
                      return (
                        <User key={member.userId} id={member.userId}>
                          {(profile) => {
                            return (
                              <>
                                <ListItem disableGutters key={profile?.id}>
                                  <>
                                    <PersonIcon color="action" />
                                    <Typography>
                                      {profile?.username} {!member.isOptional && <>*</>}
                                    </Typography>
                                  </>
                                </ListItem>
                              </>
                            );
                          }}
                        </User>
                      );
                    })}
                  </List>
                </>
              }
            >
              <Typography variant="body2" sx={{ display: 'flex', ml: { md: 'auto' }, mt: { xs: 2, md: 0 } }}>
                <WarningAmberIcon fontSize="small" sx={{ mr: 1 }} />
                {t(`${keyPrefix}.signaturesMissing`, {
                  count: calculateMissingSignatureCount(resolution)
                })}
              </Typography>
            </Tooltip>
          )}
        </Box>
      </Box>
      {Boolean(resolution?.documentsIds?.length) && (
        <DocumentList
          path={`/resolutions/${resolution.id}/documents`}
          isAccordion={true}
          showDivider={true}
          accordionOptions={{
            documentsVisible: 0
          }}
        />
      )}

      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          flexDirection: 'column',
          alignItems: 'flex-start',
          mt: 2
        }}
      >
        <Button
          variant={isSignable ? 'contained' : 'text'}
          component={RouterLink}
          to={{ pathname: toSignUrl, state: { activePath: '/to-sign', activeRootPage: '' } }}
          // data-cy="resolution-to-sign-card_cta"
        >
          {buttonLabel}
        </Button>
      </Box>
    </>
  );
};
